import type {
  EmsProduct,
  EntitlementSearchByOrdersResponse,
  EmsProductWithShipmentInfo,
} from '@customers-api-rtk-query'

export type MappedProduct = {
  product: EmsProduct
  hasProductProtection: boolean
  contractId?: string
  hasEntitlement: boolean
}
export type MappedTrackingData = {
  trackedProducts?: Array<{
    trackingInfo?: EmsProduct['tracking']
    shipmentInfo: EmsProductWithShipmentInfo['shipmentInfo']
    products: MappedProduct[]
  }>
  untrackedProducts: MappedProduct[]
}

export const mapTrackingData = (emsData: EntitlementSearchByOrdersResponse): MappedTrackingData => {
  const { trackedProducts, untrackedProducts } = emsData.products.reduce<{
    trackedProducts: Record<
      string,
      {
        trackingInfo?: EmsProduct['tracking']
        shipmentInfo: EmsProductWithShipmentInfo['shipmentInfo']
        products: MappedProduct[]
      }
    >
    untrackedProducts: MappedProduct[]
  }>(
    (acc, product) => {
      const productProtectionEntitlement = product.entitlements?.find(
        (entitlement) => entitlement.type === 'product_protection',
      )
      const hasEntitlement = !!product.entitlements?.length
      const trackingId = (product as EmsProductWithShipmentInfo).shipmentInfo?.trackingId

      if (trackingId) {
          if (!acc.trackedProducts[trackingId]) {
            acc.trackedProducts[trackingId] = {
              trackingInfo: product.tracking,
              shipmentInfo: (product as EmsProductWithShipmentInfo).shipmentInfo,
              products: [],
            }
          }
          acc.trackedProducts[trackingId].products.push({
            product,
            hasProductProtection: !!productProtectionEntitlement,
            contractId: productProtectionEntitlement?.contractId,
            hasEntitlement,
          })
      } else {
        acc.untrackedProducts.push({
          product,
          hasProductProtection: !!productProtectionEntitlement,
          contractId: productProtectionEntitlement?.contractId,
          hasEntitlement,
        })
      }
      return acc
    },
    { trackedProducts: {}, untrackedProducts: [] },
  )

  return {
    trackedProducts: Object.values(trackedProducts),
    untrackedProducts,
  }
}
