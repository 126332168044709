import React from 'react'

export const ZeroDollarsBlack = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.1831 44.664C29.7351 44.664 27.7191 43.884 26.1831 42.348C24.6951 40.86 23.6391 38.64 22.9551 35.544C22.3191 32.64 21.9951 28.776 21.9951 24.048C21.9951 16.98 22.7391 11.952 24.2631 8.66397C25.9071 5.12397 28.6071 3.32397 32.3031 3.32397C34.7511 3.32397 36.7671 4.10397 38.3031 5.63997C39.7911 7.12797 40.8471 9.34797 41.5311 12.444C42.1671 15.348 42.4911 19.212 42.4911 23.94C42.4911 31.008 41.7471 36.036 40.2231 39.324C38.5791 42.876 35.8791 44.664 32.1711 44.664H32.1831ZM32.3031 5.73598C29.5911 5.73598 27.6711 7.03197 26.4351 9.68397C25.0791 12.612 24.3951 17.448 24.3951 24.06C24.3951 28.608 24.6951 32.304 25.3071 35.028C25.8831 37.62 26.7471 39.504 27.8871 40.644C28.9791 41.724 30.3831 42.252 32.1831 42.252C34.8951 42.252 36.8151 40.956 38.0511 38.304C39.4071 35.376 40.0911 30.54 40.0911 23.928C40.0911 19.38 39.7911 15.684 39.1791 12.96C38.6031 10.368 37.7391 8.48397 36.5991 7.34397C35.5071 6.26397 34.1031 5.73598 32.3031 5.73598Z"
        fill="#141617"
      />
      <path
        d="M15.5968 18.876C14.3128 11.964 4.86876 13.884 7.12476 20.592C8.70876 24.06 13.8808 23.112 15.2248 26.88C17.3128 32.016 9.82476 35.508 7.12476 30.744C6.70476 30 6.44076 29.184 6.17676 28.356"
        stroke="#141617"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1221 11.5322V36.3122"
        stroke="#141617"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
