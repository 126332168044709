import React from 'react'

export const Arrows: React.FC = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1589_9210)">
        <path
          d="M12.8552 5.56381H36.6648C36.8952 5.56381 37.1161 5.46816 37.2789 5.29793C37.4418 5.12768 37.5334 4.89678 37.5334 4.65603V2.72834L45.6748 9.13955L37.5334 15.3172V13.2006C37.5334 12.9599 37.4418 12.729 37.2789 12.5587C37.1161 12.3885 36.8952 12.2928 36.6648 12.2928H29.9953C29.7672 12.2964 29.5496 12.3936 29.3894 12.5635C29.2293 12.7333 29.1396 12.9622 29.1396 13.2006C29.1396 13.4392 29.2293 13.668 29.3895 13.8378C29.5496 14.0077 29.7673 14.1049 29.9954 14.1084H35.7962V17.0981C35.7983 17.2646 35.8439 17.4273 35.928 17.5687C36.0121 17.7101 36.1316 17.8247 36.2735 17.9004C36.4155 17.976 36.5744 18.0096 36.7333 17.9976C36.8921 17.9857 37.0449 17.9287 37.175 17.8325L47.6457 9.88773C47.7545 9.80356 47.8432 9.69411 47.9047 9.56802C47.9661 9.44193 47.9988 9.30263 48 9.16112C48.0011 9.01959 47.9709 8.87972 47.9116 8.75252C47.8522 8.62532 47.7654 8.51426 47.658 8.4281L37.1873 0.18271C37.0583 0.0811704 36.9048 0.0192136 36.7441 0.00379652C36.5833 -0.0116206 36.4216 0.0201125 36.2771 0.0954326C36.1326 0.170752 36.0111 0.286678 35.9261 0.430191C35.8412 0.573704 35.7962 0.739123 35.7962 0.907874V3.74824H12.8552C12.6272 3.75194 12.4096 3.8492 12.2496 4.01905C12.0896 4.18888 12 4.41768 12 4.65603C12 4.89438 12.0896 5.12317 12.2496 5.29302C12.4096 5.46285 12.6272 5.56012 12.8552 5.56381Z"
          fill="#141617"
        />
        <path
          d="M3.83068 20.5633H26.9797C27.2038 20.5633 27.4185 20.4676 27.5769 20.2974C27.7353 20.1272 27.8242 19.8963 27.8242 19.6555V17.7283L35.7394 24.1395L27.8242 30.3171V28.2001C27.8242 27.9594 27.7353 27.7285 27.5769 27.5583C27.4185 27.388 27.2038 27.2924 26.9797 27.2923H20.4951C20.2733 27.2959 20.0617 27.3931 19.906 27.563C19.7503 27.7328 19.663 27.9617 19.663 28.2001C19.663 28.4387 19.7503 28.6675 19.906 28.8373C20.0617 29.0072 20.2733 29.1044 20.4951 29.1079H26.1353V32.0981C26.1373 32.2646 26.1816 32.4273 26.2634 32.5687C26.3452 32.7101 26.4613 32.8248 26.5994 32.9003C26.7373 32.976 26.8919 33.0096 27.0463 32.9976C27.2009 32.9857 27.3494 32.9286 27.4758 32.8326L37.6554 24.8873C37.7613 24.8031 37.8475 24.6936 37.9073 24.5675C37.9671 24.4415 37.9987 24.3021 38 24.1606C38.0011 24.0191 37.9716 23.8793 37.9139 23.7521C37.8562 23.6249 37.7719 23.5137 37.6674 23.4277L27.4878 15.1827C27.3624 15.0812 27.2132 15.0192 27.0568 15.0038C26.9005 14.9884 26.7433 15.0201 26.6029 15.0955C26.4624 15.1707 26.3442 15.2867 26.2616 15.4302C26.1791 15.5737 26.1353 15.7391 26.1353 15.9078V18.7477H3.83068C3.60912 18.7517 3.39786 18.849 3.24248 19.0189C3.08709 19.1886 3 19.4173 3 19.6555C3 19.8938 3.08709 20.1224 3.24248 20.2922C3.39786 20.4621 3.60912 20.5594 3.83068 20.5633Z"
          fill="#141617"
        />
        <path
          d="M27 38.3482C26.9988 38.2141 26.9663 38.0821 26.9051 37.963C26.8438 37.8438 26.7555 37.7408 26.6473 37.662L16.3425 30.1581C16.2144 30.0674 16.0641 30.0135 15.9077 30.0022C15.7514 29.9909 15.5949 30.0227 15.4552 30.0942C15.3155 30.1655 15.1979 30.2739 15.1151 30.4074C15.0325 30.541 14.9876 30.6946 14.9855 30.8518V33.6754H9.27649C9.04982 33.6754 8.8324 33.7657 8.67209 33.9265C8.51178 34.0873 8.42172 34.3054 8.42172 34.5328C8.42172 34.7602 8.51178 34.9782 8.67209 35.139C8.8324 35.2998 9.04982 35.3901 9.27649 35.3901H15.8404C16.0671 35.3901 16.2845 35.2998 16.4447 35.139C16.6051 34.9782 16.6951 34.7602 16.6952 34.5328V32.5339L24.7077 38.3683L16.6952 44.4232V42.6031C16.6951 42.3757 16.6051 42.1577 16.4447 41.9969C16.2845 41.8361 16.0671 41.7458 15.8404 41.7458H1.84164C1.61723 41.7492 1.40318 41.8411 1.24572 42.0015C1.08825 42.1619 0.99999 42.378 1 42.6032C1 42.8282 1.08827 43.0443 1.24575 43.2048C1.40322 43.3651 1.61726 43.457 1.84169 43.4604H14.9855V46.1426C14.9855 46.3019 15.0298 46.4582 15.1133 46.5938C15.197 46.7293 15.3166 46.8387 15.4588 46.9099C15.601 46.981 15.7601 47.011 15.9184 46.9964C16.0766 46.9818 16.2277 46.9234 16.3546 46.8275L26.6594 39.0406C26.7662 38.9599 26.8526 38.8552 26.9118 38.735C26.971 38.6148 27.0011 38.4823 27 38.3482Z"
          fill="#141617"
        />
        <path
          d="M37.4111 30.1674C37.2839 30.0714 37.1345 30.0143 36.9792 30.0024C36.8238 29.9904 36.6684 30.024 36.5296 30.0997C36.3908 30.1753 36.274 30.29 36.1917 30.4314C36.1095 30.5729 36.065 30.7357 36.063 30.9022V33.8929H30.3907C30.1683 33.8975 29.9565 33.9953 29.8008 34.1651C29.6451 34.3348 29.5578 34.5632 29.5579 34.801C29.5579 35.0389 29.6451 35.2672 29.8009 35.437C29.9565 35.6068 30.1684 35.7045 30.3908 35.7091H36.9122C37.1374 35.7091 37.3535 35.6134 37.5128 35.4432C37.6721 35.2728 37.7615 35.0418 37.7615 34.801V32.6838L45.7224 38.8635L37.7615 45.2769V43.3485C37.7615 43.1077 37.6721 42.8767 37.5128 42.7064C37.3535 42.5362 37.1374 42.4404 36.9122 42.4404H26.8368C26.6138 42.444 26.4009 42.5412 26.2444 42.7111C26.0877 42.8811 26 43.11 26 43.3485C26 43.587 26.0877 43.816 26.2444 43.9859C26.4009 44.1558 26.6138 44.2531 26.8368 44.2567H36.063V47.0979C36.0651 47.2658 36.1103 47.4298 36.1938 47.5719C36.2773 47.7142 36.3958 47.8289 36.5363 47.9038C36.6768 47.9788 36.8338 48.0109 36.9903 47.9967C37.1467 47.9824 37.2964 47.9225 37.4231 47.8234L47.6616 39.5751C47.7676 39.4897 47.8536 39.3789 47.9123 39.2515C47.9711 39.1242 48.0011 38.9838 48 38.8418C47.9987 38.6998 47.9664 38.56 47.9056 38.4338C47.8448 38.3076 47.757 38.1984 47.6496 38.115L37.4111 30.1674Z"
          fill="#141617"
        />
        <path
          d="M8.842 12H20.158C20.3829 11.9948 20.597 11.8871 20.7545 11.7002C20.9118 11.5132 21 11.2618 21 11C21 10.7382 20.9118 10.4868 20.7544 10.2998C20.597 10.1129 20.3829 10.0052 20.158 10H8.842C8.61715 10.0052 8.40303 10.1129 8.24561 10.2998C8.08817 10.4868 8 10.7382 8 11.0001C8 11.2618 8.08817 11.5132 8.24561 11.7002C8.40303 11.8871 8.61715 11.9948 8.842 12Z"
          fill="#141617"
        />
        <path
          d="M14 27C14 26.7348 13.9108 26.4804 13.752 26.293C13.5932 26.1054 13.3777 26 13.1531 26H0.846832C0.622238 26 0.406843 26.1054 0.248032 26.2929C0.0892198 26.4804 0 26.7348 0 27C0 27.2652 0.0892198 27.5196 0.248032 27.7071C0.406843 27.8946 0.622238 28 0.846832 28H13.1531C13.3777 28 13.5932 27.8946 13.752 27.7071C13.9108 27.5196 14 27.2652 14 27Z"
          fill="#141617"
        />
      </g>
      <defs>
        <clipPath id="clip0_1589_9210">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
