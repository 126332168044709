import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { bp, COLOR, ContentLayout, Spinner, Toast } from '@customers-ui'
import {
  useFetchPlanDetailsQuery,
  useGetContractQuery,
  useListInsuranceClaimsQuery,
  getRTKQueryErrorMessage,
  usePrecheckQuery,
  useGetPlanTermsDocumentsQuery,
} from '@customers-api-rtk-query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import type { SerializedError } from '@reduxjs/toolkit'
import { customLogger } from '@extend/client-helpers'
import ContractDetailsHeader from './contract-details-header'
import * as selectors from '../../reducers/selectors'
import type { Claim } from '../../types/claim'
import type {
  CategoryContract,
  CategoryProduct,
  ContractsSearchIndividual,
  ExtendedWarrantyContract,
  ProductProtectionBundleContract,
} from '../../types/contract'
import { OrderDetailsSection } from './order-details-section'
import { ProtectionDetailsSection } from './protection-details-section'
import { ClaimsSection } from './claims-section'
import { useSelector } from 'react-redux'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import { getDisplayDetails, getValidationErrorMessages } from '../../lib/helper-functions'
import { ClockIcon } from '../../lib/svgs/clock-icon'
import { Arrows } from '../../lib/svgs/arrows'
import { ZeroDollarsBlack } from '../../lib/svgs/zero-dollars-black'
import { ContractFaqs } from './contract-faqs'
import { useContactSupport } from '../../hooks/use-contact-support'
import { FooterBlock } from './footer-block'

export interface ProductDetailsProp {
  title?: string
  name?: string
  imageUrl?: string
  purchasePrice: number
  referenceId: string
  lineItemId?: string
}

export const isADH = (coverage: string): boolean => coverage === 'adh'
const now = Date.now()

export const ContractDetailsPage: FC = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [hasLoggedError, setHasLoggedError] = useState(false)

  const { id: contractId, lineItemId } = useParams<{ id: string; lineItemId?: string }>()
  const { contactSupport } = useContactSupport()
  const history = useHistory()

  const {
    data: contract,
    isLoading: isContractLoading,
    error: contractQueryError,
    isError: isContractQueryError,
  } = useGetContractQuery({
    contractId,
  }) as {
    data?: ContractsSearchIndividual
    isLoading: boolean
    error: FetchBaseQueryError | SerializedError | undefined
    isError: boolean
  }

  const {
    data: planDetails,
    isLoading: isPlanDetailsLoading,
    error: planDetailsQueryError,
    isError: isPlanDetailsQueryError,
  } = useFetchPlanDetailsQuery(contract?.plan?.id ?? '', {
    skip: !contract?.plan,
  })

  const {
    data: claimData,
    isLoading: isClaimsLoading,
    isSuccess: isClaimsQuerySuccess,
    error: insuranceClaimQueryError,
    isError: isInsuranceClaimQueryError,
  } = useListInsuranceClaimsQuery({
    containsContractId: contractId,
    cacheBust: now,
  })
  const { data: precheck } = usePrecheckQuery({ contractId: contract?.id }, { skip: !contract })

  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const { isEmailVerified, isPhoneNumberVerified } = getAccountInfo(consumerProfile)
  const isMissingRequiredProfileField = !isEmailVerified || !isPhoneNumberVerified

  const filteredClaims = lineItemId
    ? claimData?.items.filter((claim) => claim.products?.some((p) => p.lineItemId === lineItemId))
    : claimData?.items
  const claims = isClaimsQuerySuccess ? (filteredClaims as unknown as Claim[]) : null

  const getProductDetails = (): ProductDetailsProp | undefined => {
    if (contract?.type === 'category') {
      if (lineItemId) {
        return (contract as CategoryContract)?.productsList?.find(
          (p) => (p as CategoryProduct).lineItemId === lineItemId,
        ) as ProductDetailsProp
      }
      return (contract as CategoryContract)?.productsList?.[0] as ProductDetailsProp
    }
    if (contract?.type === 'product_protection_bundle') {
      return (contract as ProductProtectionBundleContract)?.productsList?.find(
        (product) => product.type === 'custom_bundle',
      )
    }
    return (contract as ExtendedWarrantyContract)?.product as ProductDetailsProp
  }

  const productDetails = getProductDetails() as ProductDetailsProp

  const lineItems = precheck?.status === 'success' ? precheck?.lineItems : {}

  const { isClaimFilingDisabled, fileClaimTooltip } = getDisplayDetails({ precheck, contract })

  const shouldDisableClaimFiling =
    !precheck ||
    isMissingRequiredProfileField ||
    isClaimFilingDisabled ||
    (lineItems && lineItems[lineItemId as string]?.hasActiveClaim)

  const getTooltipOverride = (): string => {
    if (!contract) return 'No contract found'
    return shouldDisableClaimFiling
      ? getValidationErrorMessages(contract).active_claim_found.default.fileClaimTooltip
      : fileClaimTooltip
  }

  const handleReload = (): void => {
    window.location.reload()
  }

  const handleDismiss = (): void => {
    setIsVisible(false)
  }

  useEffect(() => {
    const queryError = contractQueryError || insuranceClaimQueryError || planDetailsQueryError
    if (queryError) {
      if (!hasLoggedError) {
        const errorMessage = getRTKQueryErrorMessage(queryError)
        customLogger.warn(`[Err: Contract Details Page]: ${errorMessage}`, {
          contractQueryError: isContractQueryError,
          insuranceClaimQueryError: isInsuranceClaimQueryError,
          planDetailsQueryError: isPlanDetailsQueryError,
          contractId,
          lineItemId,
        })
        setHasLoggedError(true)
      }
      setIsVisible(true)
    }
  }, [contractQueryError, insuranceClaimQueryError, planDetailsQueryError, hasLoggedError])

  const handleViewClaimsClick = () => history.push('/my_claims')

  const { data: planTermsDocuments } = useGetPlanTermsDocumentsQuery(
    {
      termsId: contract?.plan.termsId ?? '',
      version: contract?.plan.termsVersion,
    },
    { skip: !contract?.plan },
  )

  const planTermsPdfUrl = planTermsDocuments?.versions[0].languages[0].url

  return (
    <ContentLayout pageTitle="My Contract Details Page">
      <PageContainer>
        <Toast
          message="We've encountered an error. Please refresh this page."
          type="danger"
          isVisible={isVisible}
          handleDismiss={handleDismiss}
          dataCy="contract-details-page-error-toast"
        >
          <RefreshButton onClick={handleReload}>Refresh</RefreshButton>
        </Toast>
        {(isContractLoading || isClaimsLoading || isPlanDetailsLoading) && (
          <SpinnerLoading data-cy="contract-details-page-spinner">
            <Spinner size="md" />
          </SpinnerLoading>
        )}
        {contract && claims && planDetails && (
          <Wrapper>
            <ContractDetailsHeader
              contract={contract}
              product={productDetails}
              planTermsPdfUrl={planTermsPdfUrl}
              fileAClaimSettings={{
                shouldDisableFileClaim: shouldDisableClaimFiling,
                fileAClaimTooltipOverride: getTooltipOverride(),
                displayFileAClaimButton: !claims || claims.length === 0,
              }}
            />
            <OrderDetailsSection contract={contract} />
            <ProtectionDetailsSection contract={contract} />
            <ClaimsSection
              claims={claims}
              contract={contract}
              product={productDetails}
              fileAClaimSettings={{
                shouldDisableFileClaim: shouldDisableClaimFiling,
                fileAClaimTooltipOverride: getTooltipOverride(),
              }}
            />
            <ContractFaqs contract={contract} />
            <FooterBlockRow>
              <FooterBlock
                icon={<ClockIcon />}
                headerText="24/7 Online support"
                buttonText="Contact Support"
                onButtonClick={contactSupport}
              >
                <div>Get your problem fixed with 24/7 online support, or give us a ring.</div>
              </FooterBlock>
              <FooterBlock
                icon={<Arrows />}
                headerText="Fast resolutions"
                buttonText="View Claims"
                onButtonClick={handleViewClaimsClick}
              >
                <div>
                  Whether it&apos;s a replacement or repair, we&apos;ll get you back to your product in no time.
                </div>
              </FooterBlock>
              <FooterBlock
                icon={<ZeroDollarsBlack />}
                headerText="Zero deductible"
                buttonText="View Terms and Conditions"
                onButtonClick={() => window.open(planTermsPdfUrl, '_blank')}
              >
                <div>After the purchase of your Extend protection, you won&apos; experience any hidden fees.</div>
              </FooterBlock>
            </FooterBlockRow>
          </Wrapper>
        )}
      </PageContainer>
    </ContentLayout>
  )
}

const PageContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
})

const Wrapper = styled.div({
  backgroundColor: COLOR.WHITE,
  display: 'flex',
  flexDirection: 'column',
  gap: 25,
  width: 'inherit',
  maxWidth: 1128,
  [bp.mobile]: {
    padding: 20,
  },
  [bp.desktop]: {
    maxWidth: 1368,
    padding: '0px 120px',
  },
})

export const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: 20,
  paddingRight: 20,
})

const RefreshButton = styled.button({
  [bp.mobile]: {
    display: 'none',
  },
  [bp.desktop]: {
    display: 'flex',
    backgroundColor: COLOR.RED['200'],
    color: COLOR.RED['700'],
    fontSize: 14,
    borderRadius: 25,
    border: `1px solid ${COLOR.RED['700']}`,
    padding: '7px 26px',
    lineHeight: '19px',
    fontWeight: 700,
    marginLeft: 44,
  },
  '&:hover': {
    cursor: 'pointer',
  },
})

const FooterBlockRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  gap: 10,
  [bp.mobile]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
