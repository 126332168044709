import React from 'react'

export const ClockIcon: React.FC = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1589_9192)">
        <path
          d="M6.35664 24C6.35664 23.3793 5.8394 22.8621 5.21871 22.8621H1.80492C1.18423 22.8621 0.666992 23.3793 0.666992 24C0.666992 24.6207 1.18423 25.1379 1.80492 25.1379H5.21871C5.8394 25.1379 6.35664 24.6207 6.35664 24Z"
          fill="#141617"
        />
        <path
          d="M47.5293 22.8621H44.1155C43.4948 22.8621 42.9775 23.3793 42.9775 24C42.9775 24.6207 43.4948 25.1379 44.1155 25.1379H47.5293C48.15 25.1379 48.6672 24.6207 48.6672 24C48.6672 23.3793 48.15 22.8621 47.5293 22.8621Z"
          fill="#141617"
        />
        <path
          d="M24.6672 42.3103C24.0465 42.3103 23.5293 42.8276 23.5293 43.4483V46.8621C23.5293 47.4827 24.0465 48 24.6672 48C25.2879 48 25.8052 47.4827 25.8052 46.8621V43.4483C25.8052 42.8276 25.2879 42.3103 24.6672 42.3103Z"
          fill="#141617"
        />
        <path
          d="M24.6672 5.68965C25.2879 5.68965 25.8052 5.17241 25.8052 4.55172V1.13793C25.8052 0.517241 25.2879 0 24.6672 0C24.0465 0 23.5293 0.517241 23.5293 1.13793V4.55172C23.5293 5.17241 24.0465 5.68965 24.6672 5.68965Z"
          fill="#141617"
        />
        <path
          d="M7.44308 33.0517L4.49481 34.7586C4.13274 34.9655 3.97757 35.4828 4.18446 35.8448C4.33964 36.1035 4.59826 36.2586 4.85688 36.2586C5.01205 36.2586 5.1155 36.2069 5.27067 36.1552L8.21895 34.4483C8.58102 34.2414 8.73619 33.7241 8.52929 33.3621C8.27067 32.9483 7.80515 32.7931 7.44308 33.0517Z"
          fill="#141617"
        />
        <path
          d="M41.5288 15.0518C41.6839 15.0518 41.7874 15.0001 41.9426 14.9484L44.8908 13.2415C45.2529 13.0346 45.4081 12.5173 45.2012 12.1553C44.9943 11.7932 44.477 11.638 44.115 11.8449L41.1667 13.5518C40.8046 13.7587 40.6495 14.2759 40.8563 14.638C40.9598 14.9484 41.2184 15.0518 41.5288 15.0518Z"
          fill="#141617"
        />
        <path
          d="M35.0634 40.4482C34.8565 40.0861 34.3393 39.9309 33.9772 40.1378C33.6152 40.3447 33.46 40.862 33.6669 41.224L35.3738 44.1723C35.529 44.4309 35.7876 44.5861 36.0462 44.5861C36.2014 44.5861 36.3048 44.5344 36.46 44.4827C36.8221 44.2758 36.9772 43.7585 36.7703 43.3964L35.0634 40.4482Z"
          fill="#141617"
        />
        <path
          d="M13.9082 3.82758C13.7013 3.46551 13.184 3.31033 12.822 3.51723C12.4599 3.72413 12.3047 4.24137 12.5116 4.60344L14.2185 7.55171C14.3737 7.81033 14.6323 7.96551 14.8909 7.96551C15.0461 7.96551 15.1496 7.91378 15.3047 7.86206C15.6668 7.65516 15.822 7.13792 15.6151 6.77585L13.9082 3.82758Z"
          fill="#141617"
        />
        <path
          d="M15.3565 40.1379C14.9945 39.931 14.4772 40.0345 14.2703 40.4483L12.5634 43.3966C12.3565 43.7586 12.46 44.2759 12.8738 44.4828C12.9772 44.5345 13.1324 44.5862 13.2876 44.5862C13.5462 44.5862 13.8048 44.431 13.96 44.1724L15.6669 41.2241C15.8738 40.8621 15.7186 40.3966 15.3565 40.1379Z"
          fill="#141617"
        />
        <path
          d="M36.5118 3.51727C36.1497 3.31037 35.6325 3.41382 35.4256 3.82761L33.7187 6.77589C33.5118 7.13796 33.6152 7.6552 34.029 7.86209C34.1325 7.91382 34.2877 7.96554 34.4428 7.96554C34.7015 7.96554 34.9601 7.81037 35.1152 7.55175L36.8221 4.60347C37.029 4.2414 36.8739 3.72416 36.5118 3.51727Z"
          fill="#141617"
        />
        <path
          d="M44.8401 34.7586L41.8918 33.0517C41.5297 32.8448 41.0125 32.9483 40.8056 33.3621C40.5987 33.7241 40.7022 34.2414 41.116 34.4483L44.0642 36.1552C44.1677 36.2069 44.3228 36.2586 44.478 36.2586C44.7366 36.2586 44.9953 36.1034 45.1504 35.8448C45.3573 35.431 45.2539 34.9655 44.8401 34.7586Z"
          fill="#141617"
        />
        <path
          d="M8.21898 13.6033L5.27071 11.8964C4.90864 11.6895 4.3914 11.793 4.1845 12.2068C3.9776 12.5689 4.08105 13.0861 4.49485 13.293L7.44313 14.9999C7.54658 15.0516 7.70174 15.1033 7.85691 15.1033C8.11553 15.1033 8.37416 14.9482 8.52933 14.6895C8.73622 14.2757 8.58105 13.8102 8.21898 13.6033Z"
          fill="#141617"
        />
        <path
          d="M35.0121 31.8621C35.3741 31.3448 35.2707 30.6724 34.8052 30.2586L27.2534 24.5172C27.3052 24.3621 27.3052 24.1552 27.3052 24C27.3052 22.8103 26.5293 21.8276 25.4431 21.4655V8.63792C25.4431 8.17241 25.081 7.86206 24.6672 7.86206C24.2534 7.86206 23.8914 8.22413 23.8914 8.63792V21.4655C22.8052 21.8276 22.0293 22.8103 22.0293 24C22.0293 25.4483 23.219 26.6379 24.6672 26.6379C25.081 26.6379 25.4948 26.5345 25.8569 26.3276L33.4086 32.069C33.6155 32.2241 33.8741 32.2759 34.081 32.2759C34.4431 32.3276 34.8052 32.1724 35.0121 31.8621Z"
          fill="#141617"
        />
      </g>
      <defs>
        <clipPath id="clip0_1589_9192">
          <rect width="48" height="48" fill="white" transform="translate(0.666992)" />
        </clipPath>
      </defs>
    </svg>
  )
}
