import type {
  CarouselPromptOption,
  ContractsV2GetResponse,
  MultiSelectPromptOption,
  SelectPromptOption,
  ClaimPhotosGetResponse,
} from '@customers-api-client'
import type {
  ClaimSessionLog,
  PrecheckErrorCode,
  PrecheckResponse,
  EmsProduct,
  EntitlementSearchByOrdersResponse,
} from '@customers-api-rtk-query'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import dayjs from 'dayjs'
import type { BadgeProps } from '@extend/zen'
import type {
  ContractsSearchIndividual,
  ExtendedWarrantyContract,
  ShippingProtectionContract,
  ProductProtectionBundleContract,
  CustomBundleProduct,
  ContractType,
  ContractProductsList,
} from '../types/contract'
import type { Claim, MappedClaimDetails, ClaimDetails } from '../types/claim'

export const missingProfileFieldTooltip = "To process your claim, let's verify your information."

export type ContractStatusType = 'active' | 'fulfilled' | 'expired'
export type SortedContracts = {
  [status in ContractStatusType]: Array<ContractsV2GetResponse | ContractsSearchIndividual>
}

const mapContractStatusBadge = (status: string): string => {
  if (
    status === 'created' ||
    status === 'live' ||
    status === 'refund_requested' ||
    status === 'refund_denied' ||
    status === 'delivered'
  ) {
    return 'Active'
  }

  if (status === 'cancelled') {
    return 'Cancelled'
  }

  if (status === 'refund_accepted' || status === 'refund_paid') {
    return 'Refunded'
  }
  if (status === 'fulfilled') {
    return 'Fulfilled'
  }
  if (status === 'expired' || status === 'terminated') {
    return 'Expired'
  }
  return 'Other'
}

const hasShippedProduct = (contract: ShippingProtectionContract): boolean => {
  return !!contract.trackingInfo?.some(
    (shipment) => shipment?.shippingState === 'shipped' || shipment?.shippingState === 'delivered',
  )
}

const isShippingContractActive = (contract: ShippingProtectionContract): boolean => {
  return !isShippingContractExpired(contract) && hasShippedProduct(contract)
}

const isShippingContractExpired = (contract: ShippingProtectionContract): boolean => {
  if (contract.trackingInfo?.length === 0) return false
  const today = dayjs()
  return contract.trackingInfo?.every((shipment) => {
    if (!shipment.actualDeliveryDate) return false
    const expirationDate = dayjs(shipment.actualDeliveryDate).add(60, 'day')
    return today.isAfter(expirationDate, 'day')
  })
}

function sortAndGroupContracts(
  contractArray: Array<ContractsV2GetResponse | ContractsSearchIndividual>,
): Record<ContractsSearchIndividual['type'], Array<ContractsSearchIndividual>> {
  const groupedContracts: Record<
    Exclude<ContractsSearchIndividual['type'], 'shipping_protection'>,
    Array<ContractsSearchIndividual>
  > = {
    pcrs: [],
    category: [],
    product_protection_bundle: [],
  }
  contractArray
    .filter(
      (contract): contract is ContractsSearchIndividual =>
        contract.type !== 'shipping_protection' &&
        (contract.type === 'pcrs' || contract.type === 'category' || contract.type === 'product_protection_bundle'),
    )
    .forEach((contract) => {
      groupedContracts[contract.type as Exclude<ContractsSearchIndividual['type'], 'shipping_protection'>].push(
        contract,
      )
    })

  Object.entries(groupedContracts).forEach(([_type, contracts]) => {
    contracts.sort((a: ContractsSearchIndividual, b: ContractsSearchIndividual) => {
      if (a.purchaseDate && b.purchaseDate) {
        return new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime()
      }
      return 0
    })
  })

  return groupedContracts as Record<ContractsSearchIndividual['type'], Array<ContractsSearchIndividual>>
}

const formatDate = (date: number): string => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    day: 'numeric',
    month: 'long',
  }
  return new Date(date).toLocaleDateString('en-US', dateOptions)
}
interface AuthError {
  code: string
}

const isCodeInError = (error: unknown): error is AuthError => {
  return typeof error === 'object' && error != null && 'code' in error
}

const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
  return typeof error === 'object' && error != null && 'status' in error
}

const getIsClaimInProgress = (
  precheckResponse?: PrecheckResponse,
  productsList?: ContractProductsList | EmsProduct[],
): boolean => {
  if (precheckResponse?.status === 'success') {
    return (
      productsList?.some(
        (product: { lineItemId?: string }) =>
          precheckResponse.lineItems && precheckResponse.lineItems[product?.lineItemId as string]?.hasActiveClaim,
      ) || false
    )
  }

  return (
    precheckResponse?.status === 'failure' &&
    ['active_claim_found', 'pending_photo_uploads'].includes(precheckResponse?.validationError)
  )
}

const getCustomBundleProduct = (contract: ProductProtectionBundleContract): CustomBundleProduct => {
  return contract?.productsList?.find((product) => product.type === 'custom_bundle') as CustomBundleProduct
}

const isExtendedWarrantyContract = (contract: ContractsSearchIndividual): contract is ExtendedWarrantyContract => {
  return contract.type === 'pcrs'
}

const isBundleContract = (contract: ContractsSearchIndividual): contract is ProductProtectionBundleContract => {
  return contract.type === 'product_protection_bundle'
}

interface DisplayDetails {
  badgeDetails: string
  badgeColor: BadgeProps['color']
  isClaimFilingDisabled: boolean
  fileClaimTooltip: string
  subBadgeText?: string
}

const getValidationErrorMessages = (
  contract: ContractsSearchIndividual,
): Record<
  PrecheckErrorCode,
  {
    default: DisplayDetails
    contractTypes?: Partial<Record<ContractType, DisplayDetails>>
  }
> => {
  return {
    invalid_contract_status: {
      default: {
        badgeDetails: mapContractStatusBadge(contract.status),
        badgeColor: 'neutral',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'This contract is not eligible to file a claim',
      },
    },
    active_claim_found: {
      default: {
        badgeDetails: mapContractStatusBadge(contract.status),
        badgeColor: 'green',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'There is currently an open claim for this contract',
        subBadgeText: 'Claim in progress',
      },
    },
    no_coverage_remaining: {
      default: {
        badgeDetails: mapContractStatusBadge(contract.status),
        badgeColor: 'neutral',
        isClaimFilingDisabled: true,
        fileClaimTooltip:
          'You have used your full limit of liability on this contract through already fulfilled claims',
      },
    },
    expired: {
      default: {
        badgeDetails: mapContractStatusBadge(contract.status),
        badgeColor: 'neutral',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'Your contract coverage has expired',
      },
      contractTypes: {
        shipping_protection: {
          badgeDetails: mapContractStatusBadge(contract.status),
          badgeColor: 'neutral',
          isClaimFilingDisabled: true,
          fileClaimTooltip: 'Your contract expires 60 days after package delivery',
        },
      },
    },
    not_yet_active: {
      default: {
        badgeDetails: mapContractStatusBadge(contract.status),
        badgeColor: 'yellow',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'Your coverage is not yet active',
      },
      contractTypes: {
        shipping_protection: {
          badgeDetails: mapContractStatusBadge(contract.status),
          badgeColor: 'yellow',
          isClaimFilingDisabled: true,
          fileClaimTooltip: 'Your contract begins upon package shipped',
        },
      },
    },
    mfr_warranty_active: {
      default: {
        badgeDetails: mapContractStatusBadge(contract.status),
        badgeColor: 'blue',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'Your contract activates after manufacturer warranty ends',
      },
    },
    pending_photo_uploads: {
      default: {
        badgeDetails: mapContractStatusBadge(contract.status),
        badgeColor: 'green',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'There is currently an open claim for this contract that requires photos',
        subBadgeText: 'Claim requires photos',
      },
    },
    service_type_invalid: {
      default: {
        badgeDetails: mapContractStatusBadge(contract.status),
        badgeColor: 'neutral',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'Filing a claim is currently unavailable, contact support for help',
      },
    },
    unknown: {
      default: {
        badgeDetails: mapContractStatusBadge(contract.status),
        badgeColor: 'neutral',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'Filing a claim is currently unavailable, contact support for help',
      },
    },
  }
}

const getDisplayDetails = ({
  precheck,
  contract,
}: {
  precheck?: PrecheckResponse
  contract?: ContractsSearchIndividual
}): DisplayDetails => {
  if (precheck?.status === 'success') {
    return {
      badgeDetails: 'Active',
      badgeColor: 'green',
      isClaimFilingDisabled: false,
      fileClaimTooltip: '',
    }
  }

  if (!contract) {
    return {
      badgeDetails: 'Unknown',
      badgeColor: 'neutral',
      isClaimFilingDisabled: true,
      fileClaimTooltip: 'Contract not found',
    }
  }

  if (precheck?.status === 'failure') {
    const errorMessage = getValidationErrorMessages(contract)[precheck.validationError]
    if (errorMessage) {
      return (contract && errorMessage?.contractTypes?.[contract.type]) ?? errorMessage.default
    }
  }

  return getValidationErrorMessages(contract).unknown.default
}

// This method returns an object keyed by claim id with the value being an object containing the claim and the associated contract
const mapToClaimDetails = (
  claims: Claim[],
  contracts: ContractsSearchIndividual[],
  emsOrdersResponse?: EntitlementSearchByOrdersResponse[],
): MappedClaimDetails => {
  const detail = claims.reduce((acc: MappedClaimDetails, claim) => {
    const matchingContract = contracts.find((contract) => contract.id === claim.contractId) as ContractsSearchIndividual

    // due to lower env data integrity issues, we may have claims without a matching contract, so we 'continue' here
    if (!matchingContract) return acc

    const matchingDetail: ClaimDetails = {
      claim,
      contract: matchingContract,
    }
    acc[claim.id.toString()] = matchingDetail
    return acc
  }, {})
  return detail
}

export interface TransformedLog {
  question: string
  answer: string | number
  id: string
}
function transformSessionLogs(sessionLogs: ClaimSessionLog[]): TransformedLog[] {
  return sessionLogs.reduce((acc: TransformedLog[], log, index, arr) => {
    if (!log.slot) return acc

    let answer = log.slotValue

    const previousLog = arr[index - 1] || {}
    const previousReply = previousLog?.reply
    const previousPrompt = previousReply?.prompt
    const previousTextMessages = previousReply?.messages.filter((msg) => msg.type === 'text')
    const previousMessages = previousTextMessages.map((msg) => msg.content).join(' ')

    const promptType = previousPrompt?.type ?? 'input'
    switch (promptType) {
      case 'datepicker':
        answer = formatDate(log.slotValue as number)
        break
      case 'addressInput':
        if (typeof log?.slotValue !== 'object') {
          throw new Error('address inputs can only be of type `Object`')
        }

        answer = Object.values(log.slotValue).reduce((str: string, address) => str.concat(`${address} `), '')
        break
      case 'input':
        answer = log.slotValue
        break
      default: {
        if (previousPrompt && 'options' in previousPrompt) {
          const previousPromptOptions: Array<MultiSelectPromptOption | SelectPromptOption | CarouselPromptOption> =
            previousPrompt?.options
          answer = previousPromptOptions.find(({ value }) => value === log.slotValue)?.outputText as string
        }
      }
    }

    if (answer) {
      acc.push({ answer, question: previousMessages, id: log.id })
    }

    return acc
  }, [])
}

function hasUnmetPhotoRequirements(claim: Claim, photoData?: ClaimPhotosGetResponse): boolean {
  const photoRequirements = claim?.photoRequirements
  if (!photoRequirements || (!photoRequirements && !photoData)) return false
  if (photoRequirements && !photoData) return true

  return photoRequirements.some(
    (requirement) => !photoData?.photoDetails.find((p) => p?.requirement?.id === requirement.id),
  )
}

function getShipmentIssueTooltip(options: {
  isMissingRequiredProfileField?: boolean
  fileClaimTooltip?: string
  hasActiveClaim?: boolean
  hasTrackingInfo?: boolean
}): string {
  const { isMissingRequiredProfileField, fileClaimTooltip, hasActiveClaim, hasTrackingInfo } = options

  if (isMissingRequiredProfileField) {
    return missingProfileFieldTooltip
  }
  if (fileClaimTooltip) {
    return fileClaimTooltip
  }
  if (hasActiveClaim) {
    return hasTrackingInfo
      ? 'There is currently an open claim on this shipment'
      : 'There are currently open claims on each of these products'
  }
  return ''
}

export {
  isShippingContractActive,
  hasShippedProduct,
  mapContractStatusBadge,
  sortAndGroupContracts,
  isCodeInError,
  isFetchBaseQueryError,
  formatDate,
  getIsClaimInProgress,
  isShippingContractExpired,
  isExtendedWarrantyContract,
  getCustomBundleProduct,
  getDisplayDetails,
  getValidationErrorMessages,
  mapToClaimDetails,
  transformSessionLogs,
  hasUnmetPhotoRequirements,
  isBundleContract,
  getShipmentIssueTooltip,
}
