import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Accordion } from '@customers-ui'
import type { ContractsSearchIndividual } from '../../types/contract'
import { useHistory } from 'react-router-dom'

interface ContractFaqsProps {
  contract: ContractsSearchIndividual
}

export const ContractFaqs: FC<ContractFaqsProps> = ({ contract }) => {
  const history = useHistory()

  const handleClick = (): void => {
    history.push(`/my_plans/${contract.id}/transfer`)
  }

  const faqList: Array<[string | JSX.Element, string | JSX.Element]> = [
    [
      'I have a question about the product I purchased or the shipping status. Who should I contact?',
      'Please contact the retailer directly for any product and shipping-related questions.',
    ],
    [
      'Can I transfer a contract to another person?',
      <div key="contract-transfer">
        Extend Product Protection makes a great addition to a gift.{' '}
        <FAQLink onClick={handleClick}>Click here to transfer</FAQLink> it to the recipient. Upon transfer, an email is
        sent to both you and the recipient.
      </div>,
    ],
    [
      'What happens if I return my product?',
      'If you return your product, the merchant returns the amount you paid for your product protection. Depending on how much time has passed since your purchase, Extend may issue a prorated refund, less any paid service.',
    ],
    [
      'Can I cancel my Extend product protection?',
      'Please contact your retailer. They will submit the cancelation request and also issue any remaining refund.',
    ],
    [
      'I haven’t received a refund for my protection plan even though I returned my product.',
      'Our retailers handle all transactions for product protection sales and refunds for Extend. Please contact your merchant directly for assistance with refunds.',
    ],
  ]

  return (
    <Section data-cy="contract-details-faqs">
      <SectionHeaderText>Frequently Asked Questions</SectionHeaderText>
      <Accordion list={faqList} />
    </Section>
  )
}

const SectionHeaderText = styled.div({
  fontSize: 24,
  fontWeight: 700,
})

const FAQLink = styled.a({
  textDecoration: 'underline',
  cursor: 'pointer',
})

const Section = styled.section({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '100%',
  gap: 15,
  ul: {
    maxWidth: '100%',
    width: '100%',
  },
})
