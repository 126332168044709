import { useTheme } from '@emotion/react'
import { useHistory } from 'react-router-dom'

export const useContactSupport = () => {
  const history = useHistory()
  const theme = useTheme()
  return {
    contactSupport: (): void => {
      if (theme.merchantConfiguration.merchantSupportUrl) {
        window.open(theme.merchantConfiguration.merchantSupportUrl, '_blank')
      } else {
        history.push('/contact')
      }
    },
  }
}
